import { getImage, IGatsbyImageData } from 'gatsby-plugin-image'

interface GraphQLResult {
  allFile: {
    edges: Array<{
      node: { absolutePath: string; childImageSharp: IGatsbyImageData }
    }>
  }
}

export function photoFor(data: GraphQLResult, filename: string) {
  const edge = data?.allFile?.edges?.find(edge =>
    edge?.node?.absolutePath?.endsWith(filename),
  )
  const image = edge?.node?.childImageSharp
  if (image && edge) {
    return getImage(edge.node.childImageSharp)
  } else {
    throw new Error(`Cannot find image for ${filename}`)
  }
}
