import React from 'react'
import styled from 'styled-components'
import theme from '../theme'

export function Page({ type, children }: Props) {
  return <PageContainer className={type ? type : ''}>{children}</PageContainer>
}

interface Props {
  type?: string
  children?: React.ReactNode
}

export default Page

const PageContainer = styled.div`
  padding-top: 32px;
  padding-bottom: 40px;

  @media screen and (min-width: ${theme.breakpoints['medium']}) {
    padding-top: 64px;
    padding-bottom: 120px;
  }

  //placeholder for responsive padding variables
  &.padding-bottom-small {
    padding-bottom: 24px;

    @media screen and (min-width: ${theme.breakpoints['medium']}) {
      padding-bottom: 64px;
    }
  }

  &.padding-top-none {
    padding-top: 0px;
  }
`
