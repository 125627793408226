import React from 'react'
import PropTypes from 'prop-types'
import { GatsbyImage } from 'gatsby-plugin-image'
import LinkedIn from '../assets/images/social-icons/linkedin.svg'
import Twitter from '../assets/images/social-icons/twitter.svg'

export function TeamMember({ name, title, photo, bio, linkedin, twitter }) {
  return (
    <div className="grid-item team-member">
      <div className="grid-item-wrapper">
        <div className="grid-item-container">
          <div className="grid-image-top">
            <GatsbyImage image={photo} alt={name} className="centered photo" />
          </div>
          <div className="grid-item-content">
            <span className="item-name">{name}</span>
            <span className="item-title">{title}</span>
            <span className="item-bio">{bio}</span>
            {linkedin ? (
              <a href={linkedin} className="item-linkedin">
                <img src={LinkedIn} alt="LinkedIn Logo" />
              </a>
            ) : null}
            {twitter ? (
              <a href={twitter} className="item-twitter">
                <img src={Twitter} alt="Twitter Logo" />
              </a>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  )
}

TeamMember.propTypes = {
  name: PropTypes.string,
  title: PropTypes.string,
  photo: PropTypes.any,
  bio: PropTypes.string,
  linkedin: PropTypes.string,
  twitter: PropTypes.string,
}

export default TeamMember
